import React, { useState } from 'react';
import { withApollo } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import Search from './Search'
import gql from 'graphql-tag'
import moment from 'moment';
import 'font-awesome/css/font-awesome.css'
import TextField from '@material-ui/core/TextField';
import { ActivityIndicator, Card, Button, Modal, Grid, Select, DatePicker, GraphQLTable } from '../generic'
import { FUNERAL_CLAIM_TYPES, CLI_CLAIM_TYPES } from "../../variables"
import { AuthConsumer } from 'react-auth-helper';
import Numeral from 'numeral'
import 'numeral/locales/en-za.js'
Numeral.locale('en-za')

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    paddingBottom: 5
  },
}))

const FUNERAL_POLICY = gql`
  query Funerals($filter: FuneralFilter!) {
    funerals(filter: $filter) {
     id
  }
}`

export const CREDIT_LIFE_POLICY = gql`
	query CreditLifes($filter: CreditLifeFilter!) {
    creditlifes(filter: $filter) {
    id
  }
}`

export const config = {
  query: gql`
    {
      claims(limit:20000) {
        id
        claimNumber
        claimType
        productType
        claimPaymentCounter
        claimant {
          id
          idNumber
          firstNames
          surname
        }
        effectiveDate
        client {
          id
          idNumber
          firstNames
          surname
        }
        fraudChecks {
          id
          type
          outcome
        }
        #  forFuneral {
        #    id
        #    coverAmount
        #    policyNumber
        #  }
        #  forMembers {
        #    memberType
        #    ageAtSignup
        #  }
        #  forCreditLife {
        #    id
        #    policyNumber
        #    policyStatus
        #    coverAmount
        #    policyNumber
        #  }
         amountClaimed
         endsPolicy
         cancelReason
         state
         createdAt
         updatedAt
       }
    }
  `,
  dataset: data => data.claims,
  fields: {
    "claimNumber": { label: 'Number' },
    // policyNumber: { label: 'Policy', accessor: row => row.forCreditLife ? row.forCreditLife.policyNumber : row.forFuneral ? row.forFuneral.policyNumber : '-' },
    claimant: { label: 'Claimant', accessor: row => row.claimant ? `${row.claimant.firstNames} ${row.claimant.surname}` : "" },
    "claimant.idNumber": { label: 'Claimant ID' },
    "productType": { label: 'Product' },
    "claimType": { label: 'Benefit', Cell: row => row.value ? row.value.replace(/_/g, ' ') : '' },
    fraudCheck: {
      label: 'Checks',
      accessor: row => (row.fraudChecks && row.fraudChecks.length > 0 ? row.fraudChecks.filter(f => f.outcome === 'PASSED').length !== row.fraudChecks.length ? 'FAILED' : 'PASSED' : '-')
    },
    // createdAt: { label: 'Initiated', Cell: row => moment(row.value).format('DD-MM-YYYY') },
    effectiveDate: { label: 'Incident', Cell: row => moment(row.value).format('DD-MM-YYYY') },
    // name: { label: 'Policy Holder', accessor: row => row.client ? `${row.client.firstNames} ${row.client.surname} ` : "" },
    // 'client.idNumber': { label: ' Id' },
    // 'forMembers[0].memberType': { label: 'Claim For', Cell: row => row.value || "Policy Holder" },
    // 'forMembers[0].ageAtSignup': { label: 'Age at Signup', Cell: row => row.value || " " },
    amountClaimed: { label: 'Amount', align: 'right', Cell: row => row.value ? Numeral(row.value).format('$ ##,###0') : '-' },
    claimPaymentCounter: { label: 'Payments', align: 'right', Cell: row => row.value && row.value > 0 ? row.value : '' },
    state: { label: 'Status', filter: 'select', Cell: row => row.value.replace(/_/g, ' ') },
    // cancelReason: { label: 'Reason' },
    createdAt: { label: 'Claim Age', Cell: row => moment(row.value).fromNow(true) },
    updatedAt: { label: 'Last Update', Cell: row => row.value ? moment(row.value).fromNow() : '-' }
  },
  defaultSorted: [{ id: 'updatedAt', desc: true }, { id: 'createdAt', desc: true }]
};

export const loadList = config.query

const handleRowPress = (history, { id }) => {
  history.push(`/claims/${id}/edit`);
};

let ClaimList = ({ client, history }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)

  const [claimType, setClaimType] = useState()
  const [productType, setProductType] = useState()
  const [policyNumber, setPolicyNumber] = useState()
  const [effectiveDate, setEffectiveDate] = useState()
  const [policyNumberError, setPolicyNumberError] = useState()
  const [spin, setSpin] = useState()

  // const claimTypeFromReason = (reason) => {
  //   setClaimReason(productType === 'FUNERAL' ? reason : ' ')
  //   setClaimType(productType === 'FUNERAL' ? reason === 'STILLBORN' ? 'STILLBORN' : 'DEATH' : reason)
  // }

  const getPolicyInfo = () => {
    setSpin(true)
    if (policyNumber && effectiveDate) {
      client
        .query({
          query: productType === 'CREDIT_LIFE' ? CREDIT_LIFE_POLICY : FUNERAL_POLICY,
          variables: {
            filter: { policyNumber }
          }
        })
        .then(result => {
          setSpin(false)
          const data = productType === 'CREDIT_LIFE' ? result.data.creditlifes : result.data.funerals
          if (data && data[0]) {
            setOpen(false);
            // history.push(`/claims/new/${data[0].id}/${effectiveDate}/${productType}/${claimType}`)
            history.push(`/claims/newbenefit/${data[0].id}/${effectiveDate.valueOf()}/${productType}/${claimType}`)
          }
          else {
            setPolicyNumberError('Policy not found')
          }
        })
    }
  }
  const onSubmit = () => {
    getPolicyInfo()
  }

  return (
  <AuthConsumer>
      {({ inGroup }) => (<>
        <div style={{ position: 'absolute', paddingTop: 5, left: '48em' }} >
          <Button color="primary" onClick={() => setOpen(true)}>New Claim</Button> &nbsp;
          <Button onClick={() => setOpenSearch(true)}>Search</Button>
        </div>
        <Card >

          <GraphQLTable config={config} onRowPress={claim => handleRowPress(history, claim)} />

          <Dialog
            title="Enter Policy Number"
            open={openSearch}
            onClose={() => setOpenSearch(false)}
            scroll="paper"
            maxWidth='sm'
            fullWidth={true}
            classes={{
              scrollPaper: classes.topScrollPaper,
              paperScrollBody: classes.topPaperScrollBody,
            }}
            aria-labelledby="funeral-dialog-title"
          >
            <DialogTitle id="simple-dialog-title">Enter Claim Number</DialogTitle>
            <Search onOk={(claimId, effectiveDate) => history.push(`/claims/${claimId}/edit/`)} />
          </Dialog>

          <Modal
            title="Create New Claim"
            open={open}
            onClose={() => setOpen(false)}
            onOk={onSubmit}
            onCancel={() => setOpen(false)}
            fullWidth={true}
            maxWidth='md'
            okText='Start'
            cancelText='Cancel'
            // disabled={!policyNumber || !effectiveDate || !productType || !claimType}
            disabled={!policyNumber || !effectiveDate || !productType || (productType === 'CREDIT_LIFE' && !claimType)}
          >
            <Grid container justifyContent="space-between" alignItems="flex-end" spacing={2} >
              <Grid item xs={3}>
                <TextField fullWidth variant="outlined" id="policynumberinput" required label="Policy Number" placeholder="Policy Number" value={policyNumber} helperText={policyNumberError} onChange={(v) => { setProductType(['YL', 'MK'].indexOf(v.target.value.toUpperCase().substring(0, 2)) > -1 ? 'CREDIT_LIFE' : 'FUNERAL'), setPolicyNumber(v.target.value.toUpperCase()) }} />
              </Grid>
              <Grid item xs={3}>
                <DatePicker fullWidth id="claimdatepicker" value={effectiveDate} disableFuture autoOk required label="Date Claim Occurred*" onChange={(v) => { setEffectiveDate(v) }} />
              </Grid>
              {/* <Grid xs={3} item >
                <Select fullWidth id="productselect" disabled required value={productType} options={PRODUCT_TYPES} label={'Product'} onChange={(v) => setProductType(v)} />
              </Grid> */}
              {productType === 'CREDIT_LIFE' && <Grid xs={3} item >
                <Select fullWidth id="claimreasonselect" required value={claimType} options={productType === 'CREDIT_LIFE' ? CLI_CLAIM_TYPES : FUNERAL_CLAIM_TYPES} label={productType === 'FUNERAL' ? 'Cause of Death' : 'Type of Claim'} onChange={(v) => setClaimType(v.target.value)} />
              </Grid>}
            </Grid>
          </Modal>
          {spin && <center><ActivityIndicator /></center>}
      </Card>
      </>
    )}
  </AuthConsumer>
  )
};

ClaimList = withApollo(ClaimList);

export default ClaimList;